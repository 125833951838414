@import "../Utilities/Utilities.scss";

.nav-container {
    width: 100vw;
    height: 10vh;
    @include box-center;
    justify-content: space-evenly;
    background: $header-gray;
    background: #5F5C5B;
    font-family: $web-font;
    font-size: $title-size;
    color: $ebony;
    position: fixed;
    top: 0;
    z-index: 1;
    #permian-session-true {
        color: $ebony;
        font-size: $title-size;
        font-weight: normal;
    };
    #permian-session-false {
        color: $ebony;
        font-size: $title-size;
        font-weight: normal;
    };
    .logout-button {
        @include login-buttons;
        border: none;
        
        &:hover {
            @include button-hover;
        };
    };
    .welcome-box {
        width: 100vw;
        @include box-center;
        justify-content: space-evenly;
        font-size: $nav-size;
        font-weight: bolder;
        .profile-route {
            color: $ebony;
            margin-left: 30px;
        };
        h6.profile-route:hover {
            font-size: 20px;
            text-decoration: underline;
            text-shadow: 0px 0px 5px $white;
        };
    };
    ul {
        width: 20vw;
        @include box-center;
        justify-content: space-between;
        text-decoration: none;
            li {
                color: $ebony;
            };
                .home-route:hover {
                font-size: 20px;
                text-decoration: underline;
                text-shadow: 0px 0px 5px $white;
                };
                .addService-route:hover {
                    @extend .home-route, :hover;
                };
    };
    .logout-button:hover {
        @include button-hover;
        outline: none;
        background: $logout;
        text-shadow: 0px 0px 10px $white;
    };
};

.guestLanding-header {
    @include login-section;
    justify-content: space-evenly;
    width: 100vw;
    height: 100%;
    font-family: $web-font;
    text-decoration: none;
};

.anchor-text {
    @include box-center;
    width: 20vw;
    height: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: $nav-size;
};


@media screen and (max-width: 1080px) {
    .welcome-box, h6, ul, button {
        width: 15vw;
        font-size: 10px;
        .logout-button {
                width: 55px;
                height: 20px;
                font-size: 10px;
        };
        .welcome-box, h6, li {
            pointer-events: none;
        };
    };
};

@media screen and (max-width: 780px) {
    .welcome-box, h6, ul, button {
        width: 16vw;
        font-size: 9px;
        .logout-button {
            width: 40px;
            height: 18px;
            font-size: 7px;
        };
    };
    .anchor-text {
        width: 40vw;
    };
};

@media screen and (max-width: 680px) {
    .welcome-box, h6, ul, button {
        width: 15vw;
        font-size: 8px;
        .logout-button {
            width: 35px;
            height: 15px;
            font-size: 6px;
        };
    };
};

@media screen and (max-width: 625px) {
    .welcome-box, h6, ul, button {
        width: 15vw;
        font-size: 7px;
        .logout-button {
            width: 34px;
            height: 14px;
            font-size: 5px;
        };
    };
};



@media screen and (max-width: 545px) {
    .welcome-box, h6, ul, button {
        width: 20vw;
        font-size: 7px;
        .logout-button {
            width: 33px;
            height: 14px;
            font-size: 5px;
        };
    };
};

@media screen and (max-width: 490px) {
    .welcome-box, h6, ul, button {
        width: 22vw;
        font-size: 6.5px;
        .logout-button {
            width: 32px;
            height: 13px;
            font-size: 5px;
        };
    };
};

@media screen and (max-width: 460px) {
    .welcome-box, h6, ul, button {
        font-size: 6.25px;
        .logout-button {
            width: 32px;
            height: 13px;
            font-size: 5px;
        };
    };
    .welcome-box h6 {
        width: 20vw;
    };
};

@media screen and (max-width: 425px) {
    .welcome-box, h6, ul, button {
        width: 10px;
        font-size: 5.5px;
    };
    .logout-button {
        width: 30px;
        height: 8px;
        font-size: 4px;
    };
};

@media screen and (max-width: 420px) {
    .welcome-box #permian-session-true {
        font-size: 30px;
    };
    .guestLanding-header #permian-session-false {
        font-size: 30px;
    };
    .guestLanding-header h6 {
        display: none;
    };
};  

@media screen and (max-width: 385px) {
    .welcome-box, h6, ul, button {
        width: 6.5vw;
        font-size: 5px;
        .logout-button {
            width: 28px;
            height: 12px;
            font-size: 4px;
        };
    };
};