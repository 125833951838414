@mixin box-center {
    display: flex;
    justify-content: center;
    align-items: center;
};

@mixin login-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
};

@mixin column-box {
    display: flex;
    flex-direction: column;
    align-items: center;
};

@mixin login-buttons {
    width: 100px;
    height: 30px;
    border-radius: 7px;
    color: $platinum;
    font-weight: bold;
    font-size: $nav-size;
    font-family: $web-font;
    background: $ebony;
    outline: none;
    border: none;
};

@mixin button-hover {
    color: $white;
    font-weight: bold;
    text-shadow: 0px 0px 5px $white;
    cursor: pointer;
};

@mixin box-background {
    border: 1px solid transparent;
    border-radius: 15px;
    padding: 50px;
    color: $ebony;
    background: $header-gray;
    background: $platinum;
    opacity: 0.95;
    font-weight: bolder;
    font-size: 25px;
    box-shadow: 0 0 10px rgba(0,0,0,0.8);
};

$web-font: 'Exo', sans-serif;
$title-size: 40px;
$nav-size: 15px;
$label-size: 22px;
$header-gray: #8C8C8C;
$white: white;
$whitesmoke: whitesmoke;
$platinum: #F2EEEC;
$ebony: #0A0708;
$lightEbony: #2E2D29;
$logout: #C62828;
