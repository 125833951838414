@import "../Utilities//Utilities.scss";

.background-services {
    background: url("https://images.unsplash.com/photo-1489997645632-af2cfd1359f6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60");
    background-size: 100% 100%;
    background-position: initial;
    background-repeat: no-repeat;
};

.main-services {
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    color: $ebony;
    font-family: $web-font;
        .services-title h1 {
            height: 10vh;
            margin-top: 50px;
            margin-bottom: 10px;
            font-size: $title-size;
        };
        #service-box {
            width: 100vw;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap: wrap;
        };
        #service-box .user-and-description {
            width: 30vw;
            height: 27vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        };
};

@media screen and (max-width: 420px) {
    h1#serviceMQ {
        font-size: 30px;
    };
};