@import "../Utilities/Utilities.scss";

.main-guestLanding {
    @include login-section;
    justify-content: space-evenly;
    width: 100vw;
    height: 100%;
    font-family: $web-font;
    background: url("https://images.unsplash.com/photo-1562237553-36ad661d6f2c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    .form-box {
        width: 40vw;
        @include login-section;
        align-items: center;
    };
    form {
        @include box-background;
        flex-direction: column;
        width: 20vw;
        height: 40vh;
        text-align: center;
        font-size: $label-size;
            input::placeholder {
                color: $ebony;
                font-family: $web-font;
            };
    };
        #existing-user-p {
            font-size: 15px;
            text-decoration: underline;
        };
        #new-user-p {
            @extend #existing-user-p;
        };
        #number-format {
            @extend #existing-user-p;
        };
        button {
            @include login-buttons;
        };
        button:hover {
            @include button-hover;
        };
};

.existing-user {
    @include column-box;
    justify-content: center;
};

.new-user {
    @extend .existing-user;
};


@media screen and (max-width: 1080px) {
    main.main-guestLanding {
        margin-top: 65px;
    };
    .main-guestLanding, h4, p, label {
        font-size: 15px;
    };
    #existing-user-p {
        font-size: 13px;
    };
};

@media screen and (max-width: 780px) {
    .main-guestLanding, h4, p, label {
        font-size: 12px;
        form button {
            width: 85px;
            height: 20px;
            font-size: 13px;
        };
        #existing-user-p {
            font-size: 10px;
        };
    };
};

@media screen and (max-width: 420px) {
    .main-guestLanding {
        flex-direction: column;
        justify-content: center;
        height: 100%;
        background: $ebony;
        form, .existing-user {
            width: 40vw;
            height: 27vh;
            margin-top: 100px;
            #existing-user-p {
                font-size: 10px;
            };
            form button {
                width: 70px;
                height: 35px;
                font-size: 10px;
            };
        };
        form, .new-user {
            width: 40vw;
            height: 50vh;
            margin-top: 225px;
        };
    };
    .main-guestLanding, h4, label {
        font-size: 15px;
    };
};