@import "../Utilities//Utilities.scss";

.background-home {
    background: url("https://images.pexels.com/photos/2391/dirty-industry-stack-factory.jpg?auto=compress&cs=tinysrgb&dpr=2&w=500");
    background-size: 100% 100%;
    background-position: initial;
    background-repeat: no-repeat;
};

.category-name {
    height: 29%;
    margin-top: 10px;
};

.category-links {
    width: 5vw;
    height: 5vh;
    @include box-center;
    @include box-background;
};

h2:hover {
    width: 6vw;
    height: 6vh;
    font-size: 30px;
    box-shadow: 0 0 25px rgba(0,0,0,5);
};

.main-homepage {
    height: 90vh;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    color: $ebony;
    font-family: $web-font;
};

.service-categories {
    height: 30vh;
    margin-top: 50px;
    margin-bottom: 10px;
    font-size: $title-size;
};

#category-box {
    width: 100vw;
    height: 65vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
};


@media screen and (max-width: 420px) {
    #homepage-title {
        font-size: 30px;
    };
    div.category-name h2 {
        font-size: 20px;
    };
};