@import "../Utilities//Utilities.scss";
@import "../HomePage/HomePage.scss";

.background-addService {
    @extend .main-homepage;
    font-family: $web-font;
    background: url("https://images.unsplash.com/photo-1496902526517-c0f2cb8fdb6a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60");
    background-size: 100% 100%;
    background-position: initial;
    background-repeat: no-repeat;
};

.addService-title {
    margin-top: 50px;
    margin-bottom: 10px;
    font-size: $title-size;
    color: $whitesmoke;
};

.category {
    width: 20vw;
    height: 20vh;
    color: $whitesmoke;
    text-align: center;
    font-weight: bolder;
    @include column-box;
    @include box-background;
    justify-content: space-evenly;
        label {
            font-size: $label-size;
        };
        .dropDown {
            outline: none;
            cursor: pointer;
            background: $ebony;
            color: $whitesmoke;
            font-family: $web-font;
        };
};

.service-description {
    width: 20vw;
    height: 25vh;
    color: $ebony;
    text-align: center;
    font-weight: bolder;
    margin-bottom: 20px;
    @include column-box;
    @include box-background;
        label {
            font-size: $label-size;
        };
            form > textarea::placeholder {
                color: $ebony;
                font-family: $web-font;
            };
                button {
                    @include login-buttons;
                    cursor: pointer;
                };
                button:hover {
                    @include button-hover;
                    text-shadow: 0px 0px 5px $whitesmoke;
                };
};

.upload-feature, p, progress {
    @include column-box;
    font-size: $nav-size;
    justify-content: center;
    text-align: center;
        label {
            @extend .upload-feature;
        };
        input {
            width: 12vw;
            outline: none;
            justify-content: center;
        };
};

.sudo-alert {
    animation: pulse 1s infinite;
    animation-timing-function: linear;
};

@keyframes pulse {
    0% { transform: scale(1)};
    50% { transform: scale(1.1)};
    100% { transform: scale(1)};
};


@media screen and (max-width: 1200px) {
    .upload-feature input {
        width: 17vw;
    };
    .category, .category-title {
        width: 20vw;
        height: 10vh;
        font-size: 30px;
    };
};

@media screen and (max-width: 1080px) {
    .upload-feature input {
        width: 17vw;
    };
    div.service-description button {
        width: 80px;
        height: 25px;
        font-size: 14px;
    };
};

@media screen and (max-width: 921px) {
    .upload-feature input {
        width: 18vw;
    };
    div.service-description button {
        width: 75px;
        height: 23px;
        font-size: 13px;
    };
};

@media screen and (max-width: 870px) {
    .upload-feature input {
        width: 20.5vw;
    };
};

@media screen and (max-width: 800px) {
    .service-description textarea {
        resize: horizontal; max-width: 215px;
    };
    .upload-feature input {
        width: 22vw;
    };
};

@media screen and (max-width: 715px) {
    .upload-feature input {
        width: 24vw;
    };
};

@media screen and (max-width: 670px) {
    .upload-feature input {
        width: 26.5vw;
    };
};

@media screen and (max-width: 600px) {
    .category, .category-title {
        width: 40vw;
        height: 15vh;
        font-size: 30px;
    };
    .service-description {
        height: 35vh;
    };
    .service-description, .service-description-title {
        width: 40vw;
        font-size: 30px;
            button {
                width: 80px;
                height: 20px;
                font-size: 12px;
            };
    };
    .service-description textarea {
        resize: horizontal; max-width: 200px;
        resize: vertical; max-height: 35px;
    };
    .upload-feature input {
        width: 29vw;
    };
};

@media screen and (max-width: 540px) {
    .upload-feature input {
        width: 32vw;
    };
};

@media screen and (max-width: 495px) {
    label.category-title, label.service-description-title {
        font-size: 18px;
    };
    .upload-feature input {
        width: 35vw;
    };
};

@media screen and (max-width: 450px) {
    .upload-feature input {
        width: 38vw;
    };
};

@media screen and (max-width: 420px) {
    .background-addService h1 {
        font-size: 30px;
    };
    label.category-title, label.service-description-title {
        font-size: 17px;
    };
    .upload-feature input {
        width: 41vw;
    };
};

@media screen and (max-width: 385px) {
    div.service-description {
        width: 40vw;
        height: 25vh;
        font-size: 20px;
    };
    .service-description-title {
        width: 40vw;
        font-size: 30px;
    };
    .upload-feature input {
        width: 45vw;
    };
};