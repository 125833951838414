@import "../Utilities/Utilities.scss";

.main-profile {
    @include column-box;
    width: 100vw;
    height: 100vh;
    color: $whitesmoke;
    font-size: $title-size;
    font-family: $web-font;
    text-align: center;
    margin-top: 150px;
    margin-bottom: 10px;
};

.profile-information {
    @include box-background;
    @include column-box;
    width: 30vw;
    height: 35vh;
    label {
        font-size: $label-size;
    };
    h3 {
        font-size: $nav-size;
    };
    .profile-buttons {
        width: 20vw;
        height: 5vh;
        @include login-section;
        justify-content: space-evenly;
        button {
            @include login-buttons;
        };
    };
};

@media screen and (max-width: 420px) {
    .main-profile h1 {
        font-size: 30px;
    };
    .profile-information {
        height: 50vh;
        .profile-information h3 {
            font-size: 1px;
        };
        .profile-buttons {
            width: 60vw;
            height: 20px;
            font-size: 12px;
            button {
                width: 22vw;
            };
        };
    };
};

@media screen and (max-width: 375px) {
    .main-profile h1 {
        font-size: 25px;
    };
};