@import "../Utilities//Utilities.scss";

.user-and-description {
    @include box-background;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin: 30px;
    text-align: center;
        textarea {
            font-family: $web-font;
                textarea::placeholder {
                    color: $ebony;
                    opacity: 0.95;
                    font-family: $web-font;
                };
        };
        button {
            @include login-buttons;
        };
            button:hover {
                @include button-hover;
            };
};

.underline {
    text-decoration: underline;
};

// pulse
.name-and-link a:hover {
    animation: pulse 1s infinite;
    animation-timing-function: linear;
};

.pdf-img {
    text-decoration: none;
};

.editFalse-info {
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
        .name-and-link {
            width: 100%;
            @include box-center;
            justify-content: space-evenly;
        };
        h1 {
            font-size: 20px;
        };
        h3 {
            font-size: $nav-size;
        };
};

#editTrue-input {
    width: 15vw;
    outline: none;
};

#upload-button {
    @include login-buttons;
    width: 55px;
    height: 18px;
    font-size: 10px;
};

.editTrue-info textarea {
    width: 100%;
    margin-bottom: 20px;
};

.stacked-buttons {
    @include box-center;
    width: 30vw;
    justify-content: space-evenly;
    flex-direction: row;
    margin-bottom: 15px;
};

.contact-labels {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
        input::placeholder {
            color: $ebony;
            opacity: 0.95;
            font-family: $web-font;
        };
        textarea::placeholder {
            color: $ebony;
            opacity: 0.95;
            font-family: $web-font;
        };
        #number-input {
            width: 22vw;
        };
};

//pulse
@keyframes pulse {
    0% { transform: scale(1)};
    50% { transform: scale(1.25)};
    100% { transform: scale(1)};
};

@media screen and (max-width: 1106px) {
    form.contact-form label {
        font-size: $nav-size;
    };
    div.editFalse-info h1 {
        font-size: 18px;
    };
    div.editFalse-info h3 {
        font-size: 12px;
    };
    div.stacked-buttons {
        width: 30vw;
    };
    #editTrue-input {
        width: 22vw;
    };
};

@media screen and (max-width: 809px) {
    .contact-labels textarea {
        resize: vertical; max-height: 30px;
        resize: horizontal; max-width: 250px;
    };
    .contact-labels #number-input {
        width: 30vw;
    };
    #editTrue-input {
        width: 24vw;
    };
};

@media screen and (max-width: 780px) {
    .editFalse-info textarea {
        resize: horizontal; max-width: 250px;
    };
    div.stacked-buttons {
        width: 40vw;
    };
    #editTrue-input {
        width: 26vw;
    };
};

@media screen and (max-width: 670px) {
    div.editFalse-info h1 {
        font-size: 18px;
    };
    div.editFalse-info h3 {
        font-size: 13px;
    };
    div.editFalse-info h1 {
        font-size: 17.75px;
    };
    div.editFalse-info h3 {
        font-size: 11.75px;
    };
    div.stacked-buttons {
        width: 45vw;
    };
    #editTrue-input {
        width: 28vw;
    };
    .editTrue-info textarea {
        resize: horizontal; max-width: 250px;
    };
};

@media screen and (max-width: 560px) {
    div.stacked-buttons {
        width: 48vw;
    };
    .editTrue-info textarea {
        resize: horizontal; max-width: 220px;
    };
    #editTrue-input {
        width: 30vw;
    };
};

@media screen and (max-width: 539px) {
    .user-and-description button {
        width: 75px;
        height: 20px;
        font-size: 12px;
    };
    .editFalse-info textarea {
        resize: horizontal; max-width: 215px;
    };
    div.editFalse-info h1 {
        font-size: 17.5px;
    };
    div.editFalse-info h3 {
        font-size: 11.5px;
    };
    .contact-labels textarea {
        resize: horizontal; max-width: 220px;
    };
    .contact-labels #number-input {
        width: 35vw;
    };
    #editTrue-input {
        width: 35vw;
    };
};

@media screen and (max-width: 560px) {
    div.stacked-buttons {
        width: 52vw;
    };
};

@media screen and (max-width: 460px) {
    .editTrue-info textarea {
        resize: horizontal; max-width: 200px;
    };
    div.stacked-buttons {
        width: 57vw;
    };
    #editTrue-input {
        width: 38vw;
    };
};

@media screen and (max-width: 431px) {
    div.editFalse-info h1 {
        font-size: 17.25px;
    };
    div.editFalse-info h3 {
        font-size: 11.25px;
    };
};

@media screen and (max-width: 421px) {
    div.editFalse-info h1 {
        font-size: 17.20px;
    };
    div.editFalse-info h3 {
        font-size: 11.20px;
    };
};

@media screen and (max-width: 420px) {
    .user-and-description button {
        width: 70px;
        height: 18px;
        font-size: 10px;
    };
    .editFalse-info textarea {
        resize: horizontal; max-width: 150px;
    };
    div.editTrue-info h1 {
        font-size: 18px;
    };
    div.editFalse-info h1 {
        font-size: 15px;
    };
    div.editFalse-info h3 {
        font-size: 11px;
    };
    div.stacked-buttons {
        width: 60vw;
    };
    .contact-labels textarea {
        resize: horizontal; max-width: 200px;
    };
    .contact-labels #number-input {
        width: 40vw;
    };
    #editTrue-input {
        width: 43vw;
    };
    #upload-button {
        width: 50px;
        height: 18px;
        font-size: 8px;
    };
};